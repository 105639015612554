// CustomHooks.js or a similarly named file

import { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import firestore from '../Data/firebase-config';

// Hook for fetching teams
export const useTeams = () => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const teamsRef = collection(firestore, 'Teams');
    const unsubscribe = onSnapshot(teamsRef, (snapshot) => {
      const teamsArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTeams(teamsArray);
    });

    // Cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return teams;
};

// Hook for team actions (add, update, delete)
export const useTeamActions = () => {
  const addTeam = async (teamData) => {
    await addDoc(collection(firestore, 'Teams'), teamData);
  };

  const updateTeam = async (teamId, teamData) => {
    const teamRef = doc(firestore, 'Teams', teamId);
    await updateDoc(teamRef, teamData);
  };

  const deleteTeam = async (teamId) => {
    await deleteDoc(doc(firestore, 'Teams', teamId));
  };

  return { addTeam, updateTeam, deleteTeam };
};
