import React, { useState, useEffect } from 'react';
import { useAnswerActions } from '../../Controllers/AnswersController';
import { useTeamWeightsActions, useTeamWeights } from '../../Controllers/TeamWeightsController';

const AnswerForm = ({ questionId, closeAddAnswer, teams, existingAnswer }) => {
    const [answerFormData, setAnswerFormData] = useState({ answerText: '' });
    const [selectedTeams, setSelectedTeams] = useState({});
    const [teamWeightsIds, setTeamWeightsIds] = useState({});
    const teamWeightsHookResult = useTeamWeights(existingAnswer ? existingAnswer.id : undefined);
    const { addAnswer, updateAnswer } = useAnswerActions();
    const { addTeamWeights, updateTeamWeights, deleteTeamWeights } = useTeamWeightsActions();
    const sortedTeams = teams.sort((a, b) => a.createdDate - b.createdDate);

    useEffect(() => {
        const initialAnswerData = existingAnswer ? { answerText: existingAnswer.answerText } : { answerText: '' };
        setAnswerFormData(initialAnswerData);

        const initialSelectedTeams = {};
        const initialTeamWeightsIds = {};
        if (existingAnswer && teamWeightsHookResult) {
            teamWeightsHookResult.forEach(teamWeight => {
                initialSelectedTeams[teamWeight.teamId] = { isChecked: true, weight: teamWeight.weight };
                initialTeamWeightsIds[teamWeight.teamId] = teamWeight.id; // Store team weight IDs
            });
        }
        setSelectedTeams(initialSelectedTeams);
        setTeamWeightsIds(initialTeamWeightsIds); // Initialize state with team weight IDs
    }, [existingAnswer, teamWeightsHookResult]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnswerFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const handleCheckboxChange = (teamId, isChecked, weight = 1) => {
        setSelectedTeams(prev => ({
            ...prev,
            [teamId]: { isChecked, weight: prev[teamId]?.weight || weight }
        }));
    };

    const handleWeightChange = (teamId, weight) => {
        if (selectedTeams[teamId]) {
            setSelectedTeams(prev => ({
                ...prev,
                [teamId]: { ...prev[teamId], weight }
            }));
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            let answerRef;
            if (!existingAnswer) {
                const currentDate = new Date();
                const answerDataWithDate = { ...answerFormData, questionId, createdDate: currentDate };
                answerRef = await addAnswer(answerDataWithDate);
            } else {
                await updateAnswer(existingAnswer.id, answerFormData);
                answerRef = existingAnswer.id; // Use existing answer ID for updates
            }
    
            const updateAndDeletePromises = Object.entries(selectedTeams).map(async ([teamId, { isChecked, weight }]) => {
                if (isChecked) {
                    if (teamWeightsIds[teamId]) {
                        // Update existing team weight
                        await updateTeamWeights(answerRef, teamWeightsIds[teamId], { weight: Number(weight) });
                    } else {
                        // Add new team weight
                        await addTeamWeights(answerRef, { teamId, weight: Number(weight) });
                    }
                } else if (teamWeightsIds[teamId]) {
                    // Team was unchecked, delete the corresponding teamWeights entry
                    await deleteTeamWeights(answerRef, teamWeightsIds[teamId]);
                }
            });
    
            // Wait for all updates and deletes to complete
            await Promise.all(updateAndDeletePromises);
    
            setAnswerFormData({ answerText: '' }); // Reset form
            setSelectedTeams({}); // Reset selected teams
            setTeamWeightsIds({}); // Reset team weights IDs
            closeAddAnswer(true);
        } catch (error) {
            console.error("Failed to save the answer or team weights:", error);
        }
    };
    

    const handleCancel = () => {
        closeAddAnswer(true);
    };

    return (
        <div className='answer-form'>
            <form onSubmit={handleSave}>
                <div className='answer-text'>
                    <div>
                        <label htmlFor='answerText'>Answer Text:</label>
                    </div>
                    <textarea
                        type="text"
                        name="answerText"
                        value={answerFormData.answerText}
                        onChange={handleChange}
                        placeholder='Enter Answer Text'
                    />
                </div>
                {sortedTeams.length > 0 ? sortedTeams.map(team => (
                    <div className='flex answer-form-inputs' key={team.id}>
                        <div className='flex team-check'>
                            <input
                                type='checkbox'
                                name="teams"
                                id={team.name}
                                onChange={(e) => handleCheckboxChange(team.id, e.target.checked)}
                                checked={selectedTeams[team.id]?.isChecked || false}
                            />
                            <label htmlFor={team.name}>{team.name}</label>
                        </div>
                        <div className={`team-weight ${!selectedTeams[team.id]?.isChecked ? 'inactive-weight' : ''}`}>          
                            <label htmlFor={team.name + "_weight"}>Weight:</label>
                            <input
                                type='number'
                                id={team.name + "_weight"}
                                onChange={(e) => handleWeightChange(team.id, e.target.value)}
                                value={selectedTeams[team.id]?.weight || 0}
                                disabled={!selectedTeams[team.id]?.isChecked}
                            />
                        </div>
                    </div>
                )) : <div>Create a team to assign answers</div>}
                <button className='add-entity' type='submit'>Save Answer</button>
                <button className='add-entity' onClick={handleCancel}>Cancel</button>
            </form>
        </div>
    );
};

export default AnswerForm;
