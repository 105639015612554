import React, { useState } from 'react';
import { useAnswerActions } from '../../Controllers/AnswersController';
import AnswerForm from './AnswerForm';

function AnswersList({ questionId, answers, teams }) {
    const { deleteAnswer } = useAnswerActions();
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentAnswerId, setCurrentAnswerId] = useState(null);
    const sortedAnswers = answers.sort((a, b) => a.createdDate - b.createdDate);

    const startEditAnswer = (answerId) => {
        setCurrentAnswerId(answerId);
        setIsEditing(true);
        setIsAdding(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsAdding(false);
        setCurrentAnswerId(null);
    }

    const confirmAndDeleteAnswer = (answerId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this answer?');
        if (isConfirmed) {
            deleteAnswer(answerId);
        }
    };

    return (
        <div className='answer-list'>
            {
                answers.length > 0 ?
                sortedAnswers.map(answer => (
                    <div className='admin-answer' key={answer.id}>
                        {isEditing && currentAnswerId === answer.id ? 
                            <AnswerForm questionId={questionId} closeAddAnswer={handleCancel} teams={teams} existingAnswer={answer} /> :
                            <div className='flex admin-answer-container'>
                                <div className='answer-title'>
                                    {answer.answerText}
                                </div>
                                <div className='control-button-container flex'>
                                    <button className='control-button' onClick={() => startEditAnswer(answer.id)}><i className="fa-regular fa-pen-to-square"></i></button>
                                    <button className='control-button' onClick={() => confirmAndDeleteAnswer(answer.id)}><i className="fa-regular fa-trash-can"></i></button>
                                </div>
                            </div>
                        }
                    </div>
                )) :
                <div>
                    No Answers
                </div>
            }
            <div>
                {
                    isAdding ? <AnswerForm questionId={questionId} closeAddAnswer={handleCancel} teams={teams} answer={null} /> :
                    <button className={`add-entity ${isEditing ? 'inactive-weight' : ''}`} onClick={() => setIsAdding(true)}>Add Answer</button>
                }
            </div>
        </div>
    );
}

export default AnswersList;
