import React, { useState } from "react";


function TopBar({setActiveTab})
{
    const [currentTab, setCurrentTab] = useState("Teams");

    const handleSetTabActive = (tab) => {
        setActiveTab(tab);
        setCurrentTab(tab);
    }

    return (
        <div>
            <button className={`top-button ${currentTab == "Teams" ? 'active' : ''}`} onClick={() => handleSetTabActive('Teams')}>Teams</button>
            <button className={`top-button ${currentTab == "Questions" ? 'active' : ''}`} onClick={() => handleSetTabActive('Questions')}>Questions</button>
        </div>
    )
}

export default TopBar;