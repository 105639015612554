import React from 'react';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-text'>Join The Fun!</div>
      <img src="https://storage2.snappages.site/ZVM5JV/assets/images/7248862_1793x1793_500.png" alt="Logo" className='logo' />
    </footer>
  );
};

export default Footer;