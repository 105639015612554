  import React, { useState } from 'react';
  import {useTeams, useTeamActions} from '../../Controllers/TeamsController';

  function TeamsList(){
      const teams = useTeams();
      const sortedTeams = teams.sort((a, b) => a.createdDate - b.createdDate);
      const { addTeam, updateTeam, deleteTeam } = useTeamActions();
      const [startAddTeam, setStartAddTeam] = useState(false);
      const [isEditing, setIsEditing] = useState(false);
      const [isAdding, setIsAdding] = useState(false);
      const [currentTeamId, setCurrentTeamId] = useState(null);
      const [teamFormData, setTeamFormData] = useState({
          name: '',
          win: '',
          weAre: '',
          weCantBe: '',
          available: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setTeamFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      };
    
      const handleAddTeamClick = (e) => {
        e.preventDefault();
        setStartAddTeam(true);
      };
    
      const handleAddTeamSubmit = async (e) => {
        e.preventDefault();
        const currentDate = new Date();
        const teamDataWithDate = { ...teamFormData, createdDate: currentDate };
        await addTeam(teamDataWithDate);
        setTeamFormData({ name: '', win: '', weAre: '', weCantBe: '', available: '' });
        setStartAddTeam(false);
      };
    
      const handleUpdateTeamSubmit = async (e) => {
        e.preventDefault();
        await updateTeam(currentTeamId, teamFormData);
        setTeamFormData({ name: '', win: '', weAre: '', weCantBe: '', available: '' });
      };

      const handleClearForm = (e) => {
          e.preventDefault();
          setTeamFormData({ name: '', win: '', weAre: '', weCantBe: '', available: '' });
          setStartAddTeam(false);
        }
    
      const startEditTeam = (team) => {
        setCurrentTeamId(team.id);
        setIsEditing(true);
        setTeamFormData(team);
      };
      
      const handleCancelEdit = () => {
        setCurrentTeamId(null);
        setIsEditing(false);
        setTeamFormData({ name: '', win: '', weAre: '', weCantBe: '', available: '' });
      }

      const toggleDetails = (id) => {
        setCurrentTeamId(currentTeamId === id ? null : id);
      };

      const confirmAndDeleteTeam = (teamId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this team?');
        if (isConfirmed) {
            deleteTeam(teamId);
        }
    };


    return (
      <div className='item-list'>
        <h2 className='item-list-header'>Teams List</h2>
        <button className='add-entity' onClick={handleAddTeamClick}>Add Team</button>
        { startAddTeam &&
          <form className='team-form' onSubmit={handleAddTeamSubmit}>
            <label htmlFor='name'>Team Name:</label>
            <input 
              type="text" 
              name="name"
              value={teamFormData.name} 
              onChange={handleChange} 
              placeholder="Enter new team name" 
            />
            <label htmlFor="teamWin">Win:</label>
            <textarea 
                id="teamWin"
                name="win"
                type="text" 
                value={teamFormData.win} 
                onChange={handleChange} 
            />
            <label htmlFor="teamWeAre">We Are:</label>
            <textarea 
                id="teamWeAre"
                type="text" 
                name="weAre"
                value={teamFormData.weAre} 
                onChange={handleChange} 
            />
            <label htmlFor="teamWeCantBe">We Can't Be:</label>
            <textarea 
                id="teamWeCantBe"
                type="text" 
                name="weCantBe"
                value={teamFormData.weCantBe} 
                onChange={handleChange} 
            />
            <label htmlFor="teamAvailability">Availability:</label>
            <textarea 
                id="teamAvailability"
                type="text" 
                name="available"
                value={teamFormData.available} 
                onChange={handleChange} 
            />
            <div className='control-button-container flex'>
              <button className='add-entity' type='submit'>Add Team</button>
              <button className='add-entity' onClick={handleClearForm}>Cancel</button>
            </div>
          </form>
        }
          {sortedTeams.map(team => (
              <div className='item-container' key={team.id}>
                  <div className='flex'>
                      <i onClick={() => toggleDetails(team.id)} className={`fa-solid fa-chevron-down chevron ${currentTeamId === team.id ? 'upward' : ''}`}></i>
                      <div className='item-title' onClick={() => toggleDetails(team.id)}>
                        {team.name}
                      </div>
                      <div className='control-button-container flex'>
                        <button onClick={() => startEditTeam(team)} className='control-button'><i className="fa-regular fa-pen-to-square"></i></button>
                        <button onClick={() => confirmAndDeleteTeam(team.id)} className='control-button'><i className="fa-regular fa-trash-can"></i></button>
                      </div>
                  </div>
                  { currentTeamId == team.id && !isEditing &&
                    <div className='team-details flex'>
                      <p><strong>Win:</strong> <br />{team.win}</p>
                      <p><strong>We Are:</strong><br />{team.weAre}</p>
                      <p><strong>We Can't Be:</strong><br />{team.weCantBe}</p>
                      <p><strong>Availability:</strong><br />{team.available}</p>
                    </div>
                  }
                  {currentTeamId == team.id && isEditing && 
                    <form className='team-form' onSubmit={handleUpdateTeamSubmit}>
                      <label htmlFor='name'>Team Name:</label>
                      <input 
                          type="text" 
                          name="name"
                          value={teamFormData.name} 
                          onChange={handleChange} 
                          placeholder="Enter new team name" 
                      />
                      <label htmlFor="teamWin">Win:</label>
                      <textarea 
                          id="teamWin"
                          name="win"
                          type="text" 
                          value={teamFormData.win} 
                          onChange={handleChange} 
                      />
                      <label htmlFor="teamWeAre">We Are:</label>
                      <textarea 
                          id="teamWeAre"
                          type="text" 
                          name="weAre"
                          value={teamFormData.weAre} 
                          onChange={handleChange} 
                      />
                      <label htmlFor="teamWeCantBe">We Can't Be:</label>
                      <textarea 
                          id="teamWeCantBe"
                          type="text" 
                          name="weCantBe"
                          value={teamFormData.weCantBe} 
                          onChange={handleChange} 
                      />
                      <label htmlFor="teamAvailability">Availability:</label>
                      <textarea 
                          id="teamAvailability"
                          type="text" 
                          name="available"
                          value={teamFormData.available} 
                          onChange={handleChange} 
                      />
                      <br/>
                      <div className='control-button-container flex'>
                        <button className='add-entity' type='submit'>Submit</button>
                        <button className='add-entity' onClick={handleCancelEdit}>Cancel</button>
                      </div>
                    </form>
                  }
              </div>
          ))}
      </div>
    );
  };

  export default TeamsList;
