import React, { useState, useEffect } from 'react';
import { useQuestions, useQuestionActions } from '../../Controllers/QuestionsController';
import {useAllAnswers} from '../../Controllers/AnswersController'
import {useTeams} from '../../Controllers/TeamsController'
import AnswersList from './AnswersList';

function QuestionsList(){
    const questions = useQuestions();
    const answers = useAllAnswers();
    const { addQuestion, updateQuestion, deleteQuestion } = useQuestionActions(); 
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [questionFormData, setQuestionFormData] = useState({ questionText: '' });
    const sortedQuestions = questions.sort((a, b) => a.createdDate - b.createdDate);
    const teams = useTeams();
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setQuestionFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const handleAddQuestionSubmit = async (e) => {
        e.preventDefault();
        setIsAdding(false);      
        const currentDate = new Date();
        const questionDataWithDate = { ...questionFormData, createdDate: currentDate };
        await addQuestion(questionDataWithDate);
        setQuestionFormData({ questionText: '' });
    };

    const handleUpdateQuestionSubmit = async (e) => {
        e.preventDefault();
        await updateQuestion(currentQuestionId, {
            ...questionFormData
        });
        setQuestionFormData({ questionText: '' });
        setIsEditing(false);
        setCurrentQuestionId(null);
    };

    const startEditQuestion = (question) => {
        setCurrentQuestionId(question.id);
        setQuestionFormData({ questionText: question.questionText });
        setIsEditing(true);
        setIsAdding(false);
    };

    const handleCancel = () =>
    {
        setIsAdding(false);
        setIsEditing(false);
        setQuestionFormData({questionText: ''});
    }

    const toggleDetails = (id) => {
        // Toggle the display of details for the clicked question
        setCurrentQuestionId(currentQuestionId === id ? null : id);
        
    };

    const confirmAndDeleteQuestion = (questionId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this question?');
        if (isConfirmed) {
            deleteQuestion(questionId);
        }
    };

    return (
        <div className='item-list'>
            <h2 className='item-list-header'>Questions List</h2>
            {!isAdding && <button className='add-entity' onClick={() => setIsAdding(true)} disabled={isEditing}>Add Question</button>}
            {!isEditing && isAdding && (
                <form onSubmit={handleAddQuestionSubmit}>
                    <textarea
                        type="text"
                        name="questionText"
                        value={questionFormData.questionText}
                        onChange={handleChange}
                        placeholder="Enter new question text"
                    />
                    <div className='question-add-button-container'>
                        <button className='add-entity' type="submit">Add</button>
                        <button className='add-entity' onClick={handleCancel}>Cancel</button>
                    </div>
                </form>
            )}

            {sortedQuestions.map(question => (
                <div className='item-container' key={question.id}>
                    {isEditing && currentQuestionId === question.id ? (
                        <form onSubmit={handleUpdateQuestionSubmit}>
                            <input
                                type="text"
                                name="questionText"
                                value={questionFormData.questionText}
                                onChange={handleChange}
                            />
                            <button type="submit">Save Question</button>
                            <button type="button" onClick={handleCancel}>Cancel</button>
                        </form>
                    ) : (
                        <div>
                            <div className='flex'>
                                <i onClick={() => toggleDetails(question.id)} className={`fa-solid fa-chevron-down chevron ${currentQuestionId === question.id ? 'upward' : ''}`}></i>
                                <div className='item-title' onClick={() => toggleDetails(question.id)}>
                                    {question.questionText}
                                </div>
                                <div className='control-button-container flex'>
                                    <button onClick={() => startEditQuestion(question)} className='control-button'><i className="fa-regular fa-pen-to-square"></i></button>
                                    <button onClick={() => confirmAndDeleteQuestion(question.id)} className='control-button'><i className="fa-regular fa-trash-can"></i></button>
                                </div>
                            </div>
                            {currentQuestionId === question.id &&
                                <AnswersList questionId={question.id} answers={answers.filter(answer => answer.questionId === question.id)} teams={teams} />
                            }
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default QuestionsList;
