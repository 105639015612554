import React, {useState} from 'react';

function RenderAnswer({ answer, onAnswerSelect, selectedAnswers }) {
    const isSelected = selectedAnswers.some(a => {
        if(a && a.id == answer.id)
        {
            return true;
        }

        return false;
    });

    return (
        <div className={`answer ${isSelected ? 'selected' : ''}`} onClick={onAnswerSelect}>
            {answer.answerText}
        </div>
    );
}
    
 export default RenderAnswer