import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './Components/Backend/AuthProvider';
import './App.css';
import Quiz from './Components/Frontend/Quiz';
import AdminBackend from './Components/Backend/AdminBackend';
import Footer from './Components/Footer';
import Login from './Components/Backend/Login';

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Quiz />} />
            <Route path="/admin" element={<PrivateRoute><AdminBackend /></PrivateRoute>} />
            <Route path='/login' element={<Login />} />
          </Routes>
        </Router>
        <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
