import { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, addDoc, updateDoc, getDocs, writeBatch, query, where } from 'firebase/firestore'; 
import firestore from '../Data/firebase-config';

export const useQuestions = () => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const questionsRef = collection(firestore, 'Questions');
    const unsubscribe = onSnapshot(questionsRef, (snapshot) => {
      const questionsArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuestions(questionsArray);
    });

    return () => unsubscribe();
  }, []);

  return questions;
};

export const useQuestionActions = () => {
  const addQuestion = async (questionData) => {
    await addDoc(collection(firestore, 'Questions'), questionData);
  };

  const updateQuestion = async (questionId, questionData) => {
    const questionsRef = doc(firestore, 'Questions', questionId);
    await updateDoc(questionsRef, questionData);
  };

  const deleteQuestion = async (questionId) => {
    const batch = writeBatch(firestore);
  
    const questionRef = doc(firestore, 'Questions', questionId);
    batch.delete(questionRef);
  
    const answersRef = collection(firestore, 'Answers');
    const answersQuery = query(answersRef, where("questionId", "==", questionId));
    const answerDocs = await getDocs(answersQuery);
  
    answerDocs.forEach((doc) => {
      batch.delete(doc.ref);
    });
  
    await batch.commit();
  };

  return { addQuestion, updateQuestion, deleteQuestion };
};
