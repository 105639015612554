import { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import firestore from '../Data/firebase-config';

export const useTeamWeights = (answerId) => {
  const [teamWeights, setTeams] = useState([]);

  useEffect(() => {
    const teamWeightsRef = collection(firestore, `Answers/${answerId}/TeamWeights`);
    const unsubscribe = onSnapshot(teamWeightsRef, (snapshot) => {
      const teamWeightsArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTeams(teamWeightsArray);
    });

    return () => unsubscribe();
  }, [answerId]);

  return teamWeights;
};

export const useAllTeamWeights = (answerIds) => {
  const [allTeamWeights, setAllTeamWeights] = useState([]);

  useEffect(() => {
    const fetchAndSubscribeTeamWeights = (answerId) => {
      const teamWeightsRef = collection(firestore, `Answers/${answerId}/TeamWeights`);
      return onSnapshot(teamWeightsRef, (snapshot) => {
        const teamWeightsArray = snapshot.docs.map(doc => ({
          answerId,
          id: doc.id,
          ...doc.data(),
        }));
        setAllTeamWeights(prevTeamWeights => {
          const filteredTeamWeights = prevTeamWeights.filter(tw => tw.answerId !== answerId);
          return [...filteredTeamWeights, ...teamWeightsArray];
        });
      });
    };

    setAllTeamWeights([]);

    const unsubscribeFunctions = answerIds.map(fetchAndSubscribeTeamWeights);

    return () => unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
  }, [answerIds]);

  return allTeamWeights;
};

export const useTeamWeightsActions = () => {
  const addTeamWeights = async (answerId, teamWeightsData) => {
    await addDoc(collection(firestore, `Answers/${answerId}/TeamWeights`), teamWeightsData);
  };

  const updateTeamWeights = async (answerId, teamWeightsId, teamWeightsData) => {
    const teamRef = doc(firestore, `Answers/${answerId}/TeamWeights`, teamWeightsId);
    await updateDoc(teamRef, teamWeightsData);
  };

  const deleteTeamWeights = async (answerId, teamWeightsId) => {
    await deleteDoc(doc(firestore, `Answers/${answerId}/TeamWeights`, teamWeightsId));
  };

  return { addTeamWeights, updateTeamWeights, deleteTeamWeights };
};
