import { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, addDoc, updateDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import firestore from '../Data/firebase-config';

export const useAnswersByQuestionId = (questionId) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (!questionId) return;

    const answersRef = collection(firestore, 'Answers');
    const answersQuery = query(answersRef, where("questionId", "==", questionId));


    const unsubscribe = onSnapshot(answersQuery, (snapshot) => {
      const answersArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAnswers(answersArray);
    });

    return () => unsubscribe();
  }, [questionId]);

  return answers;
};

export const useAllAnswers = () => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const answersRef = collection(firestore, 'Answers');

    const unsubscribe = onSnapshot(answersRef, (snapshot) => {
      const answersArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAnswers(answersArray);
    });

    return () => unsubscribe();
  }, []);

  return answers;
};

export const useAnswerActions = () => {
  const addAnswer = async (answerData) => {
    const docRef = await addDoc(collection(firestore, 'Answers'), answerData);

    return docRef.id;
  };

  const updateAnswer = async (answerId, answerData) => {
    const answerRef = doc(firestore, 'Answers', answerId);
    await updateDoc(answerRef, answerData);
  };

  const deleteAnswer = async (answerId) => {
    const answerRef = doc(firestore, 'Answers', answerId);
    const teamWeightsRef = collection(answerRef, 'TeamWeights');
    const snapshot = await getDocs(teamWeightsRef);
  
    const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
  
    await deleteDoc(answerRef);
  };


  return { addAnswer, updateAnswer, deleteAnswer };
};
