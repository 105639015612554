import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import firestore from '../../Data/firebase-config';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    const enteredUsername = username.toLowerCase();

    try {
      // Resolve username to email
      const usersRef = collection(firestore, 'Users');
      const q = query(usersRef, where("username", "==", enteredUsername));
      const querySnapshot = await getDocs(q);

      let userEmail = null;
      querySnapshot.forEach((doc) => {
        userEmail = doc.data().email; // Assuming 'email' field exists
      });

      if (!userEmail) {
        throw new Error("User not found");
      }

      // Authenticate with Firebase Auth using the resolved email
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, userEmail, password);

      // Successful login
      navigate('/admin');
    } catch (err) {
      console.error("Login error:", err);
      setError(err.message);
    }
  };

  return (
    <form className='login-form' onSubmit={handleLogin}>
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className='add-entity' type="submit">Login</button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default LoginForm;
