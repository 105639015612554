import React, { useState } from 'react';
import TeamsList from './TeamsList';
import QuestionsList from './QuestionsList';
import TopBar from './TopBar';

function AdminBackend() {
  const [activeTab, setActiveTab] = useState('Teams'); // Default to 'Teams'

  return (
    <div>
      <TopBar setActiveTab={setActiveTab} />
      {activeTab === 'Teams' && <TeamsList />}
      {activeTab === 'Questions' && <QuestionsList />}
    </div>
  );
}

export default AdminBackend;
