import React from 'react'

function ResultModal({selectedTeam, closeModal}) {
  return (
    <div className="modal">
        <div className="modal-content">
            <span className="close" onClick={() => closeModal()}>&times;</span>
            <h3>{selectedTeam?.name}</h3>
            <p>Score: {selectedTeam?.totalWeight}</p>
            <h3>Win:</h3>
            <p>{selectedTeam?.win}</p>
            <h3>We Are:</h3>
            <p>{selectedTeam?.weAre}</p>
            <h3>We Can't Be:</h3>
            <p>{selectedTeam?.weCantBe}</p>
            <h3>Availability:</h3>
            <p>{selectedTeam?.available}</p>
        </div>
    </div>
  )
}

export default ResultModal