import React from 'react';
import RenderAnswer from './RenderAnswer'
import { useAnswersByQuestionId } from '../../Controllers/AnswersController';

function RenderQuestion({ question, onAnswerSelect, selectedAnswers }) {
    const answers = useAnswersByQuestionId(question.id);
    const sortedAnswers = answers.sort((a, b) => a.createdDate - b.createdDate)


    return (
        <div className="question">
            <h2>{question.questionText}</h2>
            <div>
                {sortedAnswers.map(answer => (
                    <div className="answer-container" key={answer.id}>
                        <RenderAnswer answer={answer} onAnswerSelect={() => onAnswerSelect(answer)} selectedAnswers={selectedAnswers} />
                    </div>
                ))}
            </div>
        </div>
    );
}
    
 export default RenderQuestion