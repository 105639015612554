import React, { useState, useEffect } from 'react';
import { useQuestions } from '../../Controllers/QuestionsController';
import RenderQuestion from './RenderQuestion';
import Results from './Results';

function Quiz() {
    const questions = useQuestions();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAnswers, setSelectedAnswers] = useState(Array(questions.length).fill(null));
    const [showResults, setShowResults] = useState(false);
    const sortedQuestions = questions.sort((a, b) => a.createdDate - b.createdDate);

    const onTouchStart = (e) => {
        setTouchEnd(null); // Reset touch end to null
        setTouchStart(e.targetTouches[0].clientX);
      };
    
      // Function to handle the end of a touch
      const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
      };

        // Add and remove touch event listeners
  useEffect(() => {
    // Define the onSwipe function inside useEffect to ensure it has access to the latest state
    const onSwipe = () => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > 50;
      const isRightSwipe = distance < -50;
    
      if (isLeftSwipe && currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        // Optionally update other state here, like selected answers
      } else if (isRightSwipe && currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        // Optionally update other state here, like selected answers
      }
    };
    
  
    // Element selection and event listener setup remain the same
    const quizElement = document.querySelector('.quiz-container');
    if (quizElement) {
      quizElement.addEventListener('touchstart', onTouchStart);
      quizElement.addEventListener('touchmove', onTouchMove);
      quizElement.addEventListener('touchend', onSwipe); // Use onSwipe here
    }
  
    // Cleanup function
    return () => {
      if (quizElement) {
        quizElement.removeEventListener('touchstart', onTouchStart);
        quizElement.removeEventListener('touchmove', onTouchMove);
        quizElement.removeEventListener('touchend', onSwipe); // And also here
      }
    };
  }, [touchStart, touchEnd, currentQuestionIndex]);

    useEffect(() => {
        if (questions.length > 0) {
            setIsLoading(false);
        }
    }, [questions]);

    const handleAnswerSelect = (answer) => {
        const updatedAnswers = [...selectedAnswers];
        updatedAnswers[currentQuestionIndex] = answer;
        setSelectedAnswers(updatedAnswers);

        if(currentQuestionIndex < questions.length - 1)
        {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        else
        {
            setShowResults(true);
        }
    }

    const handleRestart = () => {
        setSelectedAnswers(Array(questions.length).fill(null));
        setCurrentQuestionIndex(0);
        setShowResults(false);
    }

    return (
        isLoading ? <div></div> :
        <div className='quiz-container'>
            {!showResults ?
                <RenderQuestion
                    key={`question-${currentQuestionIndex}`}
                    question={sortedQuestions[currentQuestionIndex]}
                    onAnswerSelect={handleAnswerSelect}
                    selectedAnswers={selectedAnswers}/> :
                <div>
                    <Results answers={selectedAnswers} onRestart={handleRestart}/>
                </div>
            }
        </div>
    );
}

export default Quiz;
