import React, { useState, useEffect, useMemo } from 'react';
import { useTeams } from '../../Controllers/TeamsController';
import { useAllTeamWeights } from '../../Controllers/TeamWeightsController';
import ResultModal from './ResultModal';

function Results({ answers, onRestart }) {
    const teams = useTeams();
    const answerIds = useMemo(() => answers.map(answer => answer.id), [answers]);
    const allTeamWeights = useAllTeamWeights(answerIds); // Assuming this returns all team weights for all answers
    const [topTeams, setTopTeams] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);

    useEffect(() => {
        if (!allTeamWeights || allTeamWeights.length === 0) {
            return;
        }

        let teamWeightsMap = new Map();

        allTeamWeights.forEach(teamWeight => {
            const { teamId, weight } = teamWeight;

            if (teamWeightsMap.has(teamId)) {
                teamWeightsMap.set(teamId, teamWeightsMap.get(teamId) + weight);
            } else {
                teamWeightsMap.set(teamId, weight);
            }
        });

        const sortedTeams = Array.from(teamWeightsMap).sort((a, b) => b[1] - a[1]);

        const top3Teams = sortedTeams.slice(0, 3).map(([teamId, weight]) => {
            const teamDetails = teams.find(team => team.id === teamId) || { name: "Team not found", id: "unknown" };
            return {
                ...teamDetails,
                totalWeight: weight
            };
        });
        

        setTopTeams(top3Teams);
    }, [allTeamWeights, teams]);

    const openModal = (team) => {
        setSelectedTeam(team);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <h2>Recommended Teams</h2>
            <ol>
            {topTeams.map(team => (
                <li>
                    <div key={team.id} onClick={() => openModal(team)} style={{ cursor: 'pointer' }}>
                        <h3>{team.name}</h3>
                    </div>
                </li>
            ))}
            </ol>
            <button className='add-entity' onClick={onRestart}>Restart</button>

            {isModalOpen && (
                <ResultModal selectedTeam={selectedTeam} closeModal={closeModal} />
            )}
        </div>
    );
}

export default Results;
